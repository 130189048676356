import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip' 

import REACT_LOGO from '../../res/imgs/logos/ost/react-logo.png'
import GRAPHQL_LOGO from '../../res/imgs/logos/ost/graphql-logo.png'
import NODEJS_LOGO from '../../res/imgs/logos/ost/nodejs-logo.svg'
import MONGODB_LOGO from '../../res/imgs/logos/ost/mongodb-logo.png'
import APOLLO_LOGO from '../../res/imgs/logos/ost/apollo-logo-2.png'
import KONG_LOGO from '../../res/imgs/logos/ost/kong-logo.png'
import MATERIALUI_LOGO from '../../res/imgs/logos/ost/material-ui-logo.svg'
import REACT_BOOTSTRAP_LOGO from '../../res/imgs/logos/ost/react-bootstrap-logo.svg'
import HTML5_LOGO from '../../res/imgs/logos/ost/html5-logo.webp'
import CSS3_LOGO from '../../res/imgs/logos/ost/css3-logo.webp'

class SkillsLearned1 extends Component {


    render() {
        return(
            <div>
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>React JS</strong>
                        </Tooltip>
                      }
                    >
                      <img src={REACT_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Material-UI</strong>
                        </Tooltip>
                      }
                    >
                      <img src={MATERIALUI_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>
                    
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>React Bootstrap</strong>
                        </Tooltip>
                      }
                    >
                      <img src={REACT_BOOTSTRAP_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>HTML5</strong>
                        </Tooltip>
                      }
                    >
                      <img src={HTML5_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>CSS3</strong>
                        </Tooltip>
                      }
                    >
                      <img src={CSS3_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>GraphQL</strong>
                        </Tooltip>
                      }
                    >
                      <img src={GRAPHQL_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>
                      
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Node.JS</strong>
                        </Tooltip>
                      }
                    >
                      <img src={NODEJS_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>
                    
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>MongoDB</strong>
                        </Tooltip>
                      }
                    >
                      <img src={MONGODB_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Apollo GraphQL</strong>
                        </Tooltip>
                      }
                    >
                     <img src={APOLLO_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Kong Gateway</strong>
                        </Tooltip>
                      }
                    >
                     <img src={KONG_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

            </div>
        )
    }

}

export default SkillsLearned1