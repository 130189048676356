import React from 'react'
import {
  Timeline,
  Events,
  // UrlButton,
  ImageEvent,
  TextEvent,
  // YouTubeEvent,
  themes, 
  createTheme
} from '@merc/react-timeline';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import OST_LOGO from '../../res/imgs/logos/ost/onesystemstech-logo.png'
import PNB_LOGO from '../../res/imgs/logos/pnb/pnb-logo.png'

import SkillsLearned1 from './skillsLearned1'
import SkillsLearned2 from './skillsLearned2';

const customTheme = createTheme(themes.default, {
  card: {
    backgroundColor: 'rgba(21, 67, 96, 0.2)',
    color: '#ffffff',
  },
  date: {
    backgroundColor: '#154360',
  },
  marker: {
    borderColor: '#154360',
  },
  timelineTrack: {
    backgroundColor: '#154360',
  },
});




class About extends React.Component {

    render(){
        return (
            <Timeline theme={customTheme}>
              <Events>
		<ImageEvent date="04/01/21">
		    <div className="App-Div-Text-Align-Events">
		        <Col xs={12}>
			  <a href="https://www.credly.com/badges/feb6897c-ffee-4533-937e-ec2d390ae4c1?source=linked_in_profile" target="_blank" rel="noopener noreferrer">
		            <img src="https://images.credly.com/size/340x340/images/68468004-5a85-4f3b-bc58-590773979486/AWS-CloudPractitioner-2020.png" style={{ maxWidth: "100%", height: "auto"}} alt="AWS Certified Cloud Practitioner"/>
			  </a>
		        </Col>
		    </div>
		</ImageEvent>
                <TextEvent date="03/13/19" text="**Making A Habit Blog** *enabled*" />
                <TextEvent date="12/15/19" text="**jovenmaningas.me** *created*" />

                <ImageEvent date="11/07/18 - Present">
                  <div className="App-Div-Text-Align-Events">

                  <Row>
                      <Col xs={12} md={2}>
                        <img src={OST_LOGO} alt="logo" className="App-img-company-logo-no-radius" />
                      </Col>
                      <Col xs={12} md={9}>
                        <h4>ONESYSTEMS Technologies</h4>
                      </Col>
                  </Row>
                  <Row style={{marginBottom: '2%'}}>
                    <Col xs={12}>
                      <h5>Software Engineer</h5>
                    </Col>
                  </Row>
                    
                    <p className="App-Events-p"> As a Full Stack Developer, I am responsible in dealing with clients, discussing the requirements to the team, and make sure we understand what the client needs. 
                        I also do User Experience Design. Developing the Front End is also part of what I do. We use React JS to write our Front End Code. As for the Back End, me and my team
                        are using NodeJS and GraphQL with Apollo Server. We follow Agile Methodology. Here are the latest technologies that I have learned here in OneSystems:  
                    </p>

                    <SkillsLearned1 />
                      
                  </div>
                </ImageEvent>

                <ImageEvent date="08/16/16 - 10/31/2018">
                  <div className="App-Div-Text-Align-Events">
                    <Row>
                      <Col xs={12} md={2}>
                        <img src={PNB_LOGO} alt="logo" className="App-img-company-logo" />
                      </Col>
                      <Col xs={12} md={9}>
                        <h3>Philippine National Bank</h3>
                      </Col>
                    </Row>
                    <Row style={{marginBottom: '2%'}}>
                      <Col xs={12}>
                        <h5>Jr. Software Engineer</h5>
                      </Col>
                    </Row>
                    
                    <p className="App-Events-p"> 
                        Starting my early career as an associate developer, I had to go through a specialized Java Training with the bank. 
                        Got my first small project, a thick client that accepts a spreadsheet, computes for the Average Daily Balance then generate a report. I used JavaFX to create the UI and Apache POI API.
                        Assigned in an on-going project where the bank upgraded its middleware written in Java 7. Was a developer of 3DSecure Project. We used Spring Boot and PostgreSQL.
                        Tasked to test and deploy in SIT, assist to test and deploy in UAT. I have also learned how to setup a clustered JBoss EAP. I was also assigned to write JMeter Test Script to
                        perform a load testing with our Java Applications. I also experienced multithreading and concurrency in Java Programming.
                    </p>
                    <p className="App-Events-p">
                        Throughout my career with Philippine National Bank, I was exposed heavily in Core Java, Spring Frameworks, Object Oriented Programming, and followed Agile Methodoloy.
                        I was also familiarized with collaboration tools like Confluence and Jira. Here are some of the technologies that I have learned and exposed with PNB:
                    </p>
                      
                    <SkillsLearned2 />

                  </div>
                </ImageEvent>
      
        
                {/* <ImageEvent
                  date="11/07/19 - Present"
                  text="You can embed images..."
                  src="https://res.cloudinary.com/dovoq8jou/image/upload/v1564772194/jellyfish.jpg"
                  alt="jellyfish swimming"
                  credit="Photo by [@tavi004](https://unsplash.com/@tavi004)"
                >
                  <div>
                    <UrlButton href="https://unsplash.com/search/photos/undersea">
                      View more undersea photos
                    </UrlButton>
                  </div>
                </ImageEvent>
        
                <YouTubeEvent
                  date="6/18/19"
                  id="6UnRHtwHGSE"
                  name="General Tso's Chicken recipe"
                  text="... and YouTube videos!"
                /> */}
              </Events>
            </Timeline>
        )
    }
}

export default About;
