import React from 'react';

import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { Typography } from '@material-ui/core';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
// import ProgressBar from 'react-bootstrap/ProgressBar'

import { Link, Element, animateScroll as scroll } from 'react-scroll'
import { Parallax, Background } from 'react-parallax';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import About from './about';

// import PARALLAX_BG_01 from '../../res/imgs/parallax_bg_01.jpg';
// import PARALLAX_BG_02 from '../../res/imgs/parallax_bg_02.jpg';
// import PARALLAX_BG_03 from '../../res/imgs/parallax_bg_03.jpg';
// import PARALLAX_BG_04 from '../../res/imgs/parallax_bg_04.png';

import '../../styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.scrollToTop = this.scrollToTop.bind(this);
      }

      scrollToTop() {
        scroll.scrollToTop();
      }

      render(){
          return (
            <div className="App">
                <Navbar variant="dark" sticky="top" fixed="top" className="App-Nav-Bar">
                    <Navbar.Brand><Link activeClass="active" className="Top" to="Top" spy={true} smooth={true} duration={500} >joven maningas</Link></Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link><Link activeClass="active" className="Professional" to="Professional" spy={true} smooth={true} duration={500} hidden>Professional</Link></Nav.Link>
                        <Nav.Link><Link activeClass="active" className="Experience" to="Experience" spy={true} smooth={true} duration={500} >Experience</Link></Nav.Link>
                        <Nav.Link><Link activeClass="active" className="Connect" to="Connect" spy={true} smooth={true} duration={500}>Connect</Link></Nav.Link>
                        <Nav.Link href="/blog">Blog</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>

                    {/* <Form inline>
                        <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                        <Button variant="outline-info">Search</Button>
                    </Form> */}
                    </Navbar>

                    
                    <Element name="Top" className="element">
                        <header className="App-header">
                            <Container className="vcenter">

                            <img src={process.env.PUBLIC_URL + '/imgs36/primary_2.jpg'} alt="avatar" className="App-img-avatar" />

                                <Typography variant="caption" display="block">
                                    HELLO, I AM
                                </Typography>

                                <Typography variant="h4" display="block" style={{fontWeight: "bold"}}>
                                    JOVEN MANINGAS
                                </Typography>

                                <Row>
                                    <Col>
                                        <Typography variant="overline" display="block" gutterBottom>
                                            SOFTWARE ENGINEER
                                        </Typography>
                                    </Col>
                                </Row>

                            </Container>
                        </header>      
                    </Element>

                    {/* <Parallax
                        strength={100}
                    >
                        <Element name="Professional" className="element" >
                            <header className="App-header">
                                <Container className="vcenter">
                                    <Typography variant="h4" display="block" style={{fontWeight: "bold"}}>
                                        PROFESSIONAL
                                    </Typography>

                                    <Row style={{marginBottom: '3%'}}>
                                        <Col>
                                            <Typography variant="overline" display="block" gutterBottom>
                                                My knowledge in software
                                            </Typography>
                                        </Col>
                                    </Row>

                                    <Row>
                                            <Col> Java </Col>
                                            <Col fluid> <ProgressBar now={80} /></Col>
                                            <Col> 80% </Col>
                                    </Row>
                                </Container>
                            </header> 
                        </Element>
                    </Parallax> */}

                    {/* <Parallax
                        strength={100}
                    >
                        <Element name="About" className="element" >
                            <header className="App-header">
                                <Container className="vcenter">
                                    PORTFOLIO
                                </Container>
                            </header> 
                        </Element>
                    </Parallax> */}
                    
                    <Parallax
                        className="App-Parallax"
                        // blur={1}
                        // bgImage={require('../../res/imgs/parallax_bg_01.jpg')}
                        // bgImageAlt="workspace"
                        strength={1000}
                    >
                        <Element name="Experience" className="element" >
                            <header className="App-header">
                                <Container className="vcenter">
                                    <Typography variant="h4" style={{fontWeight: "bold", marginBottom: "4%"}} paragraph="true"> EXPERIENCE </Typography>
                                    <About />
                                </Container>
                            </header> 
                        </Element>
                        {/* <Background className="App-Parallax-Background-Opacity">
                            <img src={PARALLAX_BG_04} alt="fill murray" className="App-Parallax-Custom-Background-Image"/>
                        </Background> */}
                    </Parallax>


                    <Parallax strength={1000} className="App-Parallax-Custom-Background">
                        <Element name="Connect">
                            <header className="App-header">
                                <Container className="vcenter">
                                    <Card className="bg-dark text-white App-Card-Contact-Me">
                                    <Card.Body>
                                        <Row>
                                            <Col md={6} xs={12} className="App-Form-Contact-Me">
                                                <Row>
                                                    <Col>
                                                        <Typography variant="h2" display="block" gutterBottom> Connect </Typography>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Typography variant="subtitle1" display="block" gutterBottom> Hi there! I'd love to here from you! </Typography>
                                                        <Typography variant="subtitle1" display="block" gutterBottom> I prefer LinkedIn messages and Twitter DMs.</Typography>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <a href="https://www.linkedin.com/in/jgmaningas/" target="_blank" rel="noopener noreferrer"><LinkedInIcon className="App-Contact-Icon" className="App-Icons-Contact-Me"/></a>
                                                        <a href="https://github.com/ashlrem" target="_blank" rel="noopener noreferrer"><GitHubIcon className="App-Contact-Icon" className="App-Icons-Contact-Me"/></a>
                                                        {/* <a href="#" target="_blank" rel="noopener noreferrer"><FacebookIcon className="App-Contact-Icon" className="App-Icons-Contact-Me"/></a> */}
                                                        <a href="https://www.instagram.com/joven.maningas/" target="_blank" rel="noopener noreferrer"><InstagramIcon className="App-Contact-Icon" className="App-Icons-Contact-Me"/></a>
                                                        <a href="https://twitter.com/ashlrem" target="_blank" rel="noopener noreferrer"><TwitterIcon className="App-Contact-Icon" className="App-Icons-Contact-Me"/></a>
                                                        {/* <a href="https://www.linkedin.com/in/jgmaningas/" target="_blank" rel="noopener noreferrer"><YouTubeIcon className="App-Contact-Icon" className="App-Icons-Contact-Me"/></a> */}
                                                    </Col>
                                                </Row>  

                                            </Col>

                                            <Col md={6} xs={12}>
                                                <Form className="App-Form-Contact-Me">
                                                    <Form.Group controlId="formGroupNameNumber">
                                                        <Form.Row>
                                                            <Col>
                                                                <Form.Control placeholder="Name" disabled/>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control placeholder="Phone Number" disabled/>
                                                            </Col>
                                                        </Form.Row>
                                                    </Form.Group>

                                                    <Form.Group controlId="formGroupEmail">
                                                        <Form.Row>
                                                            <Col>
                                                                <Form.Control placeholder="Email" disabled/>
                                                            </Col>
                                                        </Form.Row>
                                                    </Form.Group>

                                                    <Form.Group controlId="formGroupMessage">
                                                        <Form.Row>
                                                            <Col>
                                                                <Form.Control placeholder="Message" as="textarea" rows="5" disabled/>
                                                            </Col>
                                                        </Form.Row>
                                                    </Form.Group>

                                                    <Form.Group controlId="formGroupButton">
                                                        <Form.Row>
                                                            <Col>
                                                                <Button variant="success" block disabled>Send</Button>
                                                            </Col>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            
                                        </Row>

                                    </Card.Body>
                                    </Card>
                                </Container>
                            </header>
                        </Element>
                        <Background>
                            <img className="App-Parallax-Custom-Background" alt="parallax-bg"/>
                        </Background> 
                    </Parallax>
            </div>
          )
      }

}

export default Home;