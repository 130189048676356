import React from 'react';

import '../styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from '../components/home'
import Footer from './footer'

function App() {
  return (
    <div>
        <Home />
        <Footer />
    </div>
  );
}

export default App;
