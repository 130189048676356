import React from 'react';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'

class Footer extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            logo: process.env.PUBLIC_URL + 'android-chrome-192x192.png'
        }
    }

    render(){
        return(
            <div>
                 <header className="App-Footer">
                    <Container className="App-Footer-Container">
                        <Row style={{marginTop: '1%'}}>
                            <Col>
                                <p><img src={this.state.logo} style={{height: '30px', width: '30px'}} alt=""/> © 2019 Joven Maningas </p>
                            </Col>
                        </Row>
                    </Container>
                </header>
            </div>
        )
    }
}

export default Footer;