import React, { Component } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip' 

import JAVA_LOGO from '../../res/imgs/logos/pnb/java-logo-2.jpg'
import SPRING_FRAMEWORK_LOGO from '../../res/imgs/logos/pnb/spring-framework-logo-2.jpg'
import SPRING_BOOT_LOGO from '../../res/imgs/logos/pnb/spring-boot-logo.jpg'
import REDHAT_LOGO from '../../res/imgs/logos/pnb/red-hat.svg'
import JBOSS_LOGO from '../../res/imgs/logos/pnb/jboss-logo-2.png'
import MAVEN_LOGO from '../../res/imgs/logos/pnb/maven-logo-2.png'
import VAADIN_LOGO from '../../res/imgs/logos/pnb/vaadin-logo-2.png'
import POSTGRESQL_LOGO from '../../res/imgs/logos/pnb/postgresql-logo-2.png'
import JMETER_LOGO from '../../res/imgs/logos/pnb/jmeter-logo-2.webp'
import JVISUALVM_LOGO from '../../res/imgs/logos/pnb/visualvm-logo.png'

class SkillsLearned2 extends Component {


    render() {
        return(
            <div>
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Java EE</strong>
                        </Tooltip>
                      }
                    >
                      <img src={JAVA_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Apache Maven</strong>
                        </Tooltip>
                      }
                    >
                      <img src={MAVEN_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Spring Framework</strong>
                        </Tooltip>
                      }
                    >
                      <img src={SPRING_FRAMEWORK_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Spring Boot</strong>
                        </Tooltip>
                      }
                    >
                      <img src={SPRING_BOOT_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Red Hat Enterprise Linux</strong>
                        </Tooltip>
                      }
                    >
                      <img src={REDHAT_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Red Hat JBoss EAP</strong>
                        </Tooltip>
                      }
                    >
                      <img src={JBOSS_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>PostgreSQL</strong>
                        </Tooltip>
                      }
                    >
                      <img src={POSTGRESQL_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Vaadin</strong>
                        </Tooltip>
                      }
                    >
                      <img src={VAADIN_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>Apache JMeter</strong>
                        </Tooltip>
                      }
                    >
                      <img src={JMETER_LOGO} alt="logo" className="App-img-logo" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          <strong>JVisual VM</strong>
                        </Tooltip>
                      }
                    >
                      <img src={JVISUALVM_LOGO} alt="logo" className="App-img-logo-no-raduis-bg" />
                    </OverlayTrigger>

            </div>
        )
    }

}

export default SkillsLearned2